<template>
  <Home />
</template>

<script>
import Home from './pages/site/home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
</style>
