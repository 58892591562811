import axios from 'axios'

export default class Api{
    enviarContato(nome,telefone,email,msg){
        axios.post('http://backend.tilinux.com.br/api/enviar-email', {
            nome,
            telefone,
            email,
            mensagem: msg
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    }
}