<template>
  <header>
    <nav class="navbar navbar-expand-sm nav_color">
      <a href="/"><img class="logo" src="../../assets/img/logo.png" /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="bi bi-list list"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav my-2 my-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#banner_3"
              ><span>NOSSOS SERVIÇOS</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#banner_5"><span>SOBRE NÓS</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#banner_7"><span>CONTATO</span></a>
          </li>
          <li class="nav-item">
            <button class="btn_conosco" type="button">
              <span>FALE CONOSCO</span>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <div
      id="carouselExampleCaptions"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active" data-interval="4000">
          <img
            src="../../assets/img/banner_1_new.png"
            class="d-block w-100 banner1"
            alt="Bannner 1"
          />
          <img
            src="../../assets/img/banner_1_corte.png"
            class="d-block w-100 banner1_corte"
            alt="Bannner 1"
          />

          <div class="carousel-caption">
            <div class="conteudo_banner_1">
              <p class="title_banner_1">ASSISTÊNCIA TÉCNICA</p>
              <h1 class="descricao_banner_1">Cuidamos da segurança</h1>
              <h1 class="descricao2_banner_1">dos seus equipamentos</h1>
              <a href="#banner_7">
                <button class="btn_conosco2" type="button">
                  <span>FALE CONOSCO</span>
                </button>
              </a>
              <a href="#banner_3"
                ><button class="btn_servicos" type="button">
                  <span>NOSSOS SERVIÇOS</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-interval="4000">
          <img
            src="../../assets/img/banner_2_corte.png"
            class="d-block w-100 banner1_corte"
            alt="Bannner 1"
          />
          <img
            src="../../assets/img/banner_2.png"
            class="d-block w-100 banner2"
            alt="Banner 2"
          />
          <div class="carousel-caption">
            <div class="conteudo_banner_1">
              <p class="title_banner_2">TECNOLOGIA DA INFORMAÇÃO</p>
              <h1 class="descricao_banner_2">A melhor manutenção de</h1>
              <h1 class="descricao_banner_2">servidores e sistemas</h1>
              <h1 class="descricao2_banner_2">da região</h1>
              <a href="#banner_7"
                ><button class="btn_conosco2" type="button">
                  <span>FALE CONOSCO</span>
                </button></a
              >
              <a href="#banner_3">
                <button class="btn_servicos" type="button">
                  <span>NOSSOS SERVIÇOS</span>
                </button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="banner_3">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <p class="title_section_3">NOSSOS SERVIÇOS</p>
            <h1 class="descricao_section_3">O que fazemos por você</h1>
            <div class="divisor"></div>
          </div>
          <div class="col-sm-6">
            <h5 class="contratar_servicos">
              CONTRATAR SERVIÇOS<svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="40"
                fill="currentColor"
                class="bi bi-arrow-right arow"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon1.png" class="icon" />
              <h1 class="title_coluna">TI</h1>
              <p class="conteudo_coluna">
                Gestão de T.I., Locação e Manutenção de servidores, Sistema em
                nuvem, Segurança contra ransomware e vírus
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon2.png" class="icon" />
              <h1 class="title_coluna">SEGURANÇA</h1>
              <p class="conteudo_coluna">
                Câmeras e alarme monitorando, Cerca elétrica e portões
                eletrônicos
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon3.png" class="icon" />
              <h1 class="title_coluna">DATACENTER</h1>
              <p class="conteudo_coluna">
                Locação e armazenamento de espaço em nuvem
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon4.png" class="icon" />
              <h1 class="title_coluna">INFORMÁTICA</h1>
              <p class="conteudo_coluna">
                Formatação de computadores, Locação e manutenção de impressoras,
                conserto de micros e notebooks
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon5.png" class="icon" />
              <h1 class="title_coluna">DESENVOLVIMENTO</h1>
              <p class="conteudo_coluna">
                Desenvolvimento de sites e sistemas, e-commece, hospedagem de
                sites e e-mails
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="coluna">
              <img src="../../assets/img/icon6.png" class="icon" />
              <h1 class="title_coluna">FIREWALL</h1>
              <p class="conteudo_coluna">
                Gerenciamento de permissão de aceso de páginas e segurança de
                portas em rede
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="banner_4">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="num">
              <h1 class="title_num">+10</h1>
              <p class="p_anos">Anos de experiência</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="num">
              <h1 class="title_num">+150</h1>
              <p class="p_anos">De clientes satisfeitos</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="num">
              <h1 class="title_num">+20</h1>
              <p class="p_anos">Servidores ativos</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="num">
              <h1 class="title_num">+100</h1>
              <p class="p_anos">Sistemas implantados</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="banner_5">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <p class="title_section_3">SOBRE NÓS</p>
            <h1 class="descricao_section_3">Nossa história</h1>
            <div class="divisor"></div>
            <div class="text_sobre_nos">
              <p>
                A TI Linux Informática atua no mercado de TI com vários Serviço
                para desenvolvimento da usa empresa desde o ano de 2012. A
                empresa foi fundada por profissionais altamente qualificados,
                com mais de 20 anos de experiência no ramo de TI com soluções na
                área Gerencia T.I, Manutenção e Segurança. Somos uma empresa
                sólida, com uma carteira de mais de 1800 clientes ativos que
                utilizam nossas soluções diariamente.
              </p>
              <p>MISSÃO:</p>
              <p>
                A missão da empresa é proporcionar satisfação aos clientes
                através da busca de melhoria contínua, com o comércio de
                tecnologia como computadores, notebooks, peças e suprimentos de
                informática, software gerencial, industrial e contábil,
                Segurança de dados, Serviço de Data Center, Sistema de Segurança
                contra vírus ransomware na busca de proporcionar oportunidades
                de trabalho digno e garantia de desenvolvimento da empresa. Tudo
                isso se faz com o real comprometimento da organização em fazer
                um melhor, com resultados confiáveis, éticos e transparentes.
              </p>
              <p>VALORES:</p>
              <p>
                Clientes: Atender, satisfazer e encantar, promovendo a busca de
                soluções e melhorias contínuas. <br />
                Parceiros: Estimular a integração e cooperação com clientes,
                fabricantes e fornecedores, agindo com ética, lealdade e
                responsabilidade, e respondendo por nosso desempenho.
              </p>
              <p>DIFERENCIAIS:</p>
              <p>
                Tradição: Atuando a 15 anos no mercado de Maringá e região.<br />
                Confiabilidade: Comercializamos as melhores marcas e garantimos
                os produtos.<br />
                Preço: Através de parcerias bem sucedidas conseguimos oferecer
                nossos produtos e serviços com preço altamente competitivos.
              </p>
              <p>EQUIPE:</p>
              <p>
                Respeitar os seres humanos pela singularidade e identidade,
                valorizando a sabedoria e a eficácia da auto-escolha; motivar a
                busca da auto-realização e promover o espírito de equipe, que
                prevalece sobre o individual.
              </p>
            </div>
            <div class="row">
              <div class="col-4">
                <img class="icon_section5" src="../../assets/img/icon7.png" />
                <p class="descricao_icon_banner5">Preço acessível</p>
              </div>
              <div class="col-4">
                <img class="icon_section5" src="../../assets/img/icon8.png" />
                <p class="descricao_icon_banner5">Suporte 7 dias por semana</p>
              </div>
              <div class="col-4">
                <img class="icon_section5" src="../../assets/img/icon9.png" />
                <p class="descricao_icon_banner5">Equipe qualificada</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="placa_mae_div">
              <img class="placa_mae" src="../../assets/img/placamae.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="banner_6">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <p class="title_section_6">FEEDBACK DOS CLIENTES</p>
            <h1 class="descricao_section_6">Quem conhece</h1>
            <h1 class="descricao_section_6_recomenda">recomenda</h1>
            <div class="divisor"></div>
            <p class="descricao_banner_6">Mais de 150 clientes confiam e</p>
            <p class="descricao_banner_6_recomendam">
              recomendam em nosso trabalho
            </p>
          </div>
          <div class="col-sm-6 offset-sm-2 coluna_z">
            <div class="coluna2">
              <img class="aspa" src="../../assets/img/aspa.png" />
              <p class="p_coluna2_1">
                Responsabilidade, Qualidade e Competencia
              </p>
              <strong
                ><p class="p_coluna2_2">Roberto Aeras - Via Google</p></strong
              >
              <img class="perfil" src="../../assets/img/perfil.png" />
            </div>
            <div class="coluna2">
              <img class="aspa" src="../../assets/img/aspa.png" />
              <p class="p_coluna2_1">Serviços de primeira</p>
              <strong
                ><p class="p_coluna2_2">Neo Daemon - Via Google</p></strong
              >
              <img class="perfil" src="../../assets/img/perfil2.png" />
            </div>
            <div class="coluna2">
              <img class="aspa" src="../../assets/img/aspa.png" />
              <p class="p_coluna2_1">Bom atendimento</p>
              <strong
                ><p class="p_coluna2_2">Cibelle Andrade - Via Google</p></strong
              >
              <img class="perfil" src="../../assets/img/perfil3.png" />
            </div>
          </div>
        </div>
      </div>
      <img class="banner_3" src="../../assets/img/banner_3.png" />
    </section>
    <section id="banner_7">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="title_section_7">CONTATO</p>
            <h1 class="descricao_section_7">Fale conosco</h1>
            <div class="divisor"></div>
          </div>
        </div>
        <div
          class="
            col-md-4
            md-offset-1
            d-none d-sm-block d-md-none d-block d-sm-none
          "
        >
          <div class="space">
            <strong
              ><p class="txt_section_7">Horário de funcionamento</p></strong
            >
            <p class="p_section_7">De segunda a domingo, das 7:30 às 21:00</p>
          </div>
          <strong><p class="txt_section_7">Telefone</p></strong>
          <p class="p_section_7">(44) 3025-2725</p>

          <strong> <p class="txt_section_7">E-mail</p></strong>
          <p class="p_section_7">contato@tilinux.com.br</p>

          <strong><p class="txt_section_7">Visite-nos</p></strong>
          <p class="p_section_7">Rua Santos Dumont, 2314, Sala 601 - Maringá - PR</p>
          <div class="maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.068204808951!2d-51.93528033309524!3d-23.42190324019823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd11b88aa5339%3A0xd3dd877f70bffff5!2sR.%20Santos%20Dumont%2C%202314%20-%20Zona%2003%2C%20Maring%C3%A1%20-%20PR%2C%2087013-050!5e0!3m2!1spt-BR!2sbr!4v1705511182832!5m2!1spt-BR!2sbr"
              width="100%"
              height="200"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="coluna_form">
              <form>
                <div class="row">
                  <div class="col-sm-6">
                    <label for="name">Nome</label>
                    <input type="text" id="name" v-model="nome" />
                  </div>
                  <div class="col-sm-6">
                    <label for="tel_zap">Telefone/Whatsapp</label>
                    <input type="text" id="tel_zap" v-model="telefone" />
                  </div>
                </div>
                <div>
                  <label for="mail">E-mail</label>
                  <input type="email" id="mail" v-model="email" />
                </div>
                <div>
                  <label for="msg">Em que podemos te ajudar?</label>
                  <textarea id="msg" v-model="msg"></textarea>
                </div>
                <div class="">
                  <button class="btn_form" type="button" @click="enviarContato">
                    Fale conosco
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 offset-1 d-sm-none d-md-block d-none d-sm-block">
            <div class="">
              <strong
                ><p class="txt_section_7">Horário de funcionamento</p></strong
              >
              <p class="p_section_7">
                De segunda a sexta, das 08:00 às 12:00 e das 13:30 às 18:00
              </p>
            </div>
            <strong><p class="txt_section_7">Telefone</p></strong>
            <p class="p_section_7">(44) 3025-2725</p>

            <strong><p class="txt_section_7">Celular</p></strong>
            <p class="p_section_7">(44) 99804-5495</p>

            <strong> <p class="txt_section_7">E-mail</p></strong>
            <p class="p_section_7">contato@tilinux.com.br</p>

            <strong> <p class="txt_section_7">Plantão 24H</p></strong>
            <p class="p_section_7" style="margin-bottom: 0">
              (44) 99804-5495 - Eduardo
            </p>
            <p class="p_section_7" style="margin-top: 0">
              (44) 99139-4311 - Anderson
            </p>

            <strong><p class="txt_section_7">Visite-nos</p></strong>
            <p class="p_section_7">Rua Santos Dumont 2314. Sala 601</p>
            <div class="maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.068204808951!2d-51.93528033309524!3d-23.42190324019823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd11b88aa5339%3A0xd3dd877f70bffff5!2sR.%20Santos%20Dumont%2C%202314%20-%20Zona%2003%2C%20Maring%C3%A1%20-%20PR%2C%2087013-050!5e0!3m2!1spt-BR!2sbr!4v1705511182832!5m2!1spt-BR!2sbr"
                width="100%"
                height="200"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer>
    <div class="row">
      <div class="col-sm-6">
        <a href="/"> <img class="logo" src="../../assets/img/logo.png" /></a>
      </div>
      <div class="col-sm-6">
        <p class="copy">
          Todos os direitos reservados © TI LINUX
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import Api from "../../services/api";
export default {
  data() {
    return {
      nome: "",
      telefone: "",
      email: "",
      msg: "",
    };
  },
  created() {
    this.api = new Api();
  },
  methods: {
    enviarContato() {
      this.api
        .enviarContato(this.nome, this.telefone, this.email, this.msg)
        .then((data) => {
          if (data.status == 200) {
            alert("Sua mensagem foi enviada. Em breve entraremos em contato!");
            this.nome = "";
            this.telefone = "";
            this.email = "";
            this.msg = "";
          }
        });
    },
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style>
html {
  scroll-behavior: smooth;
}
section {
  padding-top: 50px;
}

.btn_conosco {
  display: none;
}
header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}
main {
  margin-top: 90px;
}
.logo {
  margin-left: 100px;
}
.nav_color {
  background-color: rgba(37, 37, 37);
  backdrop-filter: blur(19.5px);
  padding: 25px;
}
.nav-link {
  color: #fff;
  transition: 0.5s;
  font-family: "PoppinsMedium";
}
.nav-link:hover {
  color: rgb(255, 0, 0);
}
.btn_conosco {
  background-color: #ff0000;
  border: #ff0000 solid 1px;
  border-radius: 2px;
  color: #fff;
  padding: 5px;
  font-family: "PoppinsBold";
  margin-right: 100px;
  transition: 0.3s;
}
.btn_conosco:hover {
  background-color: #8d0707;
  border: #8d0707 solid 1px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 20px;
  padding-left: 20px;
}
/*---banner_1---*/
.banner1 {
}
.title_banner_1 {
  color: #ff0000;
  margin-bottom: 0px;
  line-height: 1;
  font-family: "PoppinsMedium";
}
.descricao_banner_1 {
  color: #fff;
  margin-bottom: 0;
  font-size: 50px;
  font-family: "PoppinsBold";
}

.descricao2_banner_1 {
  color: #fff;
  margin-bottom: 40px;
  line-height: 1;
  font-size: 50px;
  font-family: "PoppinsBold";
}
.btn_conosco2 {
  background-color: #ff0000;
  border: rgb(255, 0, 0) solid 1px;
  border-radius: 2px;
  color: #fff;
  padding: 5px;
  font-weight: 700;
  margin-right: 10px;
  transition: 0.3s;
  font-family: "PoppinsMedium";
}
.btn_conosco2:hover {
  background-color: #8d0707;
  border: #8d0707 solid 1px;
}
.btn_servicos {
  background-color: transparent;
  border: #fff solid 1px;
  border-radius: 2px;
  color: #fff;
  padding: 5px;
  font-weight: 700;
  transition: 0.3s;
  font-family: "PoppinsMedium";
}
.btn_servicos:hover {
  background-color: #ff0000;
}
.conteudo_banner_1 {
  margin-top: -450px;
  text-align: left;
  margin-left: -70px;
}
/*---banenr_2---*/
.banner2 {
}
.title_banner_2 {
  color: #ff0000;
  margin-bottom: 0px;
  line-height: 1;
  font-family: "PoppinsMedium";
}
.descricao_banner_2 {
  color: #fff;
  font-family: "PoppinsBold";
  margin-bottom: 0;
  font-size: 50px;
}
.descricao2_banner_2 {
  color: #fff;
  font-family: "PoppinsBold";
  margin-bottom: 40px;
  line-height: 1;
  font-size: 50px;
}
/*---<section id="banner_3">---*/
#banner_3 {
  background: linear-gradient(180deg, #171717 0%, #1e1e1e 100%);
  padding-bottom: 50px;
}
.title_section_3 {
  color: #ff0000;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 50px;
  font-family: "PoppinsMedium";
}
.descricao_section_3 {
  color: #fff;
  font-family: "PoppinsBold";
  margin-bottom: 20px;
  font-size: 30pt;
}
.divisor {
  background: linear-gradient(270deg, #ff0000 0%, #252525 100%);
  height: 10px;
  width: 182px;
  left: 0px;
  top: 88px;
  border-radius: 0px;
}
.contratar_servicos {
  color: #fff;
  padding-top: 85px;
  text-align: right;
  font-family: "PoppinsMedium";
}
.arow {
  color: #fff;
  margin-left: 10px;
  margin-top: -1px;
}
.coluna {
  margin-top: 70px;
  padding: 40px;
  height: 300px;
  background: #252525;
  box-shadow: 0px 10px 43px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  text-align: left;
}
.icon {
  margin-top: 10px;
  margin-left: -5px;
}
.title_coluna {
  color: #fff;
  font-size: 15pt;
  font-family: "PoppinsBold";
  padding-top: 10px;
  line-height: 1;
}
.conteudo_coluna {
  color: #fff;
  margin-bottom: 0;
  font-family: "PoppinsRegular";
}
/*---<section id="banner_4">---*/
.title_num {
  color: rgb(255, 0, 0);
  font-weight: 700;
  font-family: "PoppinsMedium";
}
.p_anos {
  color: #ff0000;
  font-weight: 700;
  font-family: "PoppinsMedium";
}
.num {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
/*---<section id="banner_5">---*/
#banner_5 {
  background: linear-gradient(180deg, #171717 0%, #1e1e1e 100%);
}
.text_sobre_nos {
  color: #fff;
  padding-top: 40px;
  text-align: left;
  font-size: 10pt;
  font-family: "PoppinsLight";
}
.icon_section5 {
  padding-top: 30px;
}
.descricao_icon_banner5 {
  color: #ff0000;
  margin-bottom: 0;
  font-size: 12pt;
  font-family: "PoppinsMedium";
}
.placa_mae_div {
  text-align: center;
}
.placa_mae {
  width: 420px;
  margin-left: 100px;
  margin-top: 504px;
}
/*---<section id="banner_6">---*/
#banner_6 {
  position: relative;
  background-color: #ffffff;
  height: 688px;
}
.title_section_6 {
  color: #ff0000;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 50px;
  font-family: "PoppinsMedium";
}
.descricao_section_6 {
  color: #000;
  font-size: 30pt;
  line-height: 1;
  font-family: "PoppinsBold";
}
.descricao_section_6_recomenda {
  color: #000;
  font-family: "PoppinsBold";
  margin-bottom: 20px;
  font-size: 30pt;
  line-height: 1;
}
.descricao_banner_6 {
  padding-top: 15px;
  line-height: 1;
  margin-bottom: 0;
  font-family: "PoppinsRegular";
}
.descricao_banner_6_recomendam {
  line-height: 1;
  margin-bottom: 0;
  margin-top: 1px;
  font-family: "PoppinsRegular";
}
.banner_3 {
  width: 100%;
  position: absolute;
  top: 425px;
  z-index: 50;
}
.coluna_z {
  z-index: 100;
}
.coluna2 {
  z-index: 100;
  width: 100%;
  height: 160px;
  background: #ffffff;
  box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-top: 25px;
  padding: 30px;
}
.perfil {
  float: right;
  margin-top: -100px;
  width: 80px;
}
.p_coluna2_1 {
  margin-bottom: 0;
  margin-top: 10px;
  font-family: "PoppinsRegular";
}
.p_coluna2_2 {
  font-family: "PoppinsRegular";
}
/*---<section id="banner_7">---*/

.title_section_7 {
  color: #ff0000;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 65px;
  font-family: "PoppinsMedium";
}
.descricao_section_7 {
  color: rgb(0, 0, 0);
  font-family: "PoppinsBold";
  margin-bottom: 20px;
  font-size: 30pt;
}
.coluna_form {
  width: 100%;
  padding: 30px;
  background: #1e1e1e;
  border-radius: 28px;
  margin-top: 30px;
  margin-bottom: 50px;
  height: 500px;
}
form {
  width: 100%;
}
label {
  color: white;
  text-align: left;
  font-size: 15pt;
  font-family: "PoppinsMedium";
}
input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: #333333 solid 1px;
  border-radius: 5px;
  background: #333333;
  color: white;
  resize: none;
  height: 50px;
  font-family: "PoppinsRegular";
}
textarea {
  vertical-align: top;
  height: 150px;
  color: white;
}
textarea:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}
.btn_form {
  background-color: #ff0000;
  border: rgb(255, 0, 0) solid 1px;
  border-radius: 2px;
  color: #fff;
  padding: 15px;
  margin-top: 20px;
  font-family: "PoppinsBold";
  margin-right: 10px;
  transition: 0.3s;
  width: 100%;
}

.btn_form:hover {
  background-color: #8d0707;
  border: #8d0707 solid 1px;
}
.txt_section_7 {
  margin-bottom: 0;
  font-family: "PoppinsRegular";
}
.p_section_7 {
  color: #505050;
  font-family: "PoppinsRegular";
}
.space {
  margin-top: 50px;
}
/*---footer---*/
footer {
  background-color: #252525;
  padding: 20px;
}
.copy {
  color: #fff;
  text-align: right;
  margin-top: 10px;
}
.banner1_corte {
  display: none !important;
}
.banner2_corte {
  display: none !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title_banner_1 {
    padding-top: 135px;
  }
  .descricao_banner_1 {
    font-size: 40px;
  }
  .descricao2_banner_1 {
    font-size: 40px;
  }
  .title_banner_2 {
    padding-top: 135px;
  }
  .descricao_banner_2 {
    font-size: 40px;
  }
  .descricao2_banner_2 {
    font-size: 40px;
  }
  .descricao_section_3 {
    font-size: 27pt;
  }
  .descricao_section_6 {
    font-size: 27pt;
  }
  .descricao_section_6_recomenda {
    font-size: 27pt;
  }
  .descricao_section_7 {
    font-size: 27pt;
  }
  .descricao_icon_banner5 {
    margin-bottom: 20px;
  }
  .placa_mae {
    margin-left: 80px;
    margin-top: 131px;
  }
  .p_coluna2_1 {
    font-size: 10pt;
  }
  .p_coluna2_2 {
    font-size: 10pt;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .list {
    color: #fff;
  }
  .logo {
    margin-left: 0;
  }
  .btn_conosco {
    margin-right: 0;
  }
  .title_banner_1 {
    padding-top: 210px;
  }
  .descricao_banner_1 {
    font-size: 30pt;
  }
  .descricao2_banner_1 {
    font-size: 30pt;
  }
  .title_banner_2 {
    padding-top: 210px;
  }
  .descricao_banner_2 {
    font-size: 30pt;
  }
  .descricao2_banner_2 {
    font-size: 30pt;
  }
  .descricao_section_3 {
    font-size: 20pt;
  }
  .title_section_3 {
    font-size: 10pt;
  }
  .contratar_servicos {
    padding-top: 62px;
  }
  .coluna {
    padding: 25px;
  }
  .icon {
    margin-top: 0;
    width: 50px;
  }
  .title_coluna {
    font-size: 12pt;
  }
  .placa_mae {
    margin-left: -10px;
    margin-top: 140px;
    width: 355px;
  }
  .text_sobre_nos {
    font-size: 11pt;
  }
  .descricao_icon_banner5 {
    font-size: 9px;
    text-align: left;
  }
  .descricao_section_6 {
    font-size: 20pt;
  }
  .descricao_section_6_recomenda {
    font-size: 20pt;
  }
  .title_section_6 {
    font-size: 10pt;
  }
  .aspa {
    width: 30px;
  }
  .perfil {
    width: 55px;
    margin-top: -80px;
  }
  .p_coluna2_1 {
    font-size: 10pt;
  }
  .p_coluna2_2 {
    font-size: 10pt;
  }
  .title_section_7 {
    padding-top: 0;
    font-size: 10pt;
  }
  .descricao_section_7 {
    font-size: 20pt;
  }
  label {
    font-size: 10pt;
  }
  textarea,
  input {
    font-size: 9pt;
  }
  .txt_section_7 {
    font-size: 11pt;
  }
  .p_section_7 {
    font-size: 10pt;
  }
  .copy {
    font-size: 10pt;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logo {
    margin-left: 0;
  }
  .btn_conosco {
    margin-right: 0;
    font-size: 9pt;
  }
  .nav-link {
    font-size: 9pt;
  }
  .title_banner_1 {
    padding-top: 800px;
    font-size: 10pt;
  }
  .descricao_banner_1 {
    font-size: 20pt;
  }
  .descricao2_banner_1 {
    font-size: 20pt;
    margin-bottom: 25px;
  }
  .btn_servicos {
    width: 25%;
    font-size: 8pt;
  }
  .btn_conosco2 {
    width: 25%;
    font-size: 8pt;
  }
  .title_banner_2 {
    padding-top: 800px;
    font-size: 10pt;
  }
  .descricao_banner_2 {
    font-size: 20pt;
  }
  .descricao2_banner_2 {
    font-size: 20pt;
    margin-bottom: 25px;
  }
  .descricao_section_3 {
    font-size: 14pt;
  }
  .contratar_servicos {
    padding-top: 61px;
    font-size: 11pt;
  }
  .coluna {
    margin-top: 60px;
    padding: 30px;
  }
  .icon {
    margin-top: 0px;
    width: 55px;
  }
  .title_coluna {
    padding-top: 5px;
    font-size: 12pt;
  }
  .conteudo_coluna {
    font-size: 11pt;
  }
  .num {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text_sobre_nos {
    padding-top: 30px;
    font-size: 11pt;
  }
  .icon_section5 {
    padding-top: 10px;
    width: 50px;
  }
  .descricao_icon_banner5 {
    font-size: 7pt;
  }
  .placa_mae {
    width: 260px;
    margin-left: 0;
    margin-top: 330px;
  }
  .aspa {
    width: 20px;
  }
  .perfil {
    display: none;
  }
  .p_coluna2_1 {
    font-size: 10pt;
    text-align: center;
  }
  .p_coluna2_2 {
    font-size: 11px;
    text-align: center;
  }
  .banner_3 {
    top: 450px;
  }
  .title_section_6 {
    font-size: 9pt;
  }
  .descricao_section_6 {
    font-size: 14pt;
    margin-bottom: 0;
  }
  .descricao_section_6_recomenda {
    font-size: 14pt;
  }
  .descricao_banner_6 {
    font-size: 10pt;
  }
  .descricao_banner_6_recomendam {
    font-size: 10pt;
  }
  #banner_6 {
    height: 540px;
  }
  .title_section_7 {
    font-size: 10pt;
  }
  .descricao_section_7 {
    font-size: 14pt;
  }
  .copy {
    font-size: 10pt;
  }
}
@media (max-width: 575px) {
  .banner1 {
    display: none !important;
  }
  .banner2 {
    display: none !important;
  }
  .btn_conosco2 {
    display: none;
  }
  .btn_servicos {
    display: none;
  }
  .title_banner_1 {
    margin-top: 320px;
    font-size: 10pt;
    margin-left: 50px;
  }
  .descricao_banner_1 {
    font-size: 10pt;
  }
  .descricao2_banner_1 {
    font-size: 10pt;
  }
  .title_banner_2 {
    margin-top: 320px;
    font-size: 10pt;
    margin-left: 50px;
  }
  .descricao_banner_2 {
    font-size: 12pt;
  }
  .descricao2_banner_2 {
    font-size: 12pt;
  }
  .arow {
    display: none;
  }
  .contratar_servicos {
    display: none;
  }
  .title_section_3 {
    padding-top: 20px;
    font-size: 10pt;
  }
  .banner1_corte {
    display: block !important;
  }
  .descricao2_banner_1 {
    font-size: 10pt;
    margin-left: 50px;
  }
  .descricao_banner_1 {
    font-size: 10pt;
    margin-left: 50px;
  }
  .descricao2_banner_2 {
    font-size: 10pt;
    margin-left: 50px;
  }
  .descricao_banner_2 {
    font-size: 10pt;
    margin-left: 50px;
  }
  .logo {
    margin-left: 0;
  }
  .descricao_section_3 {
    font-size: 12pt;
  }
  .title_section_3 {
    font-size: 8pt;
  }
  .divisor {
    height: 8px;
    width: 135px;
  }
  .coluna {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    padding: 35px;
  }
  .title_coluna {
    font-size: 13pt;
  }
  .conteudo_coluna {
    font-size: 11pt;
  }
  .text_sobre_nos {
    font-size: 11pt;
    text-align: center;
  }
  .descricao_icon_banner5 {
    font-size: 10pt;
    text-align: left;
    margin-left: 10px;
  }
  .icon_section5 {
    margin-left: 10px;
  }
  .placa_mae {
    width: 300px;
    margin-left: 0;
    margin-top: 0;
  }
  .title_section_6 {
    font-size: 8pt;
  }
  .descricao_section_6 {
    font-size: 12pt;
  }
  .descricao_section_6_recomenda {
    font-size: 12pt;
  }
  .descricao_banner_6_recomendam {
    font-size: 10pt;
  }
  .descricao_banner_ {
    font-size: 10pt;
  }
  .aspa {
    width: 25px;
  }
  .perfil {
    margin-top: -50px;
    width: 60px;
  }
  .p_coluna2_1 {
    font-size: 9pt;
  }
  .p_coluna2_2 {
    font-size: 10pt;
  }
  .banner_3 {
    top: 675px;
  }
  #banner_6 {
    height: 705px;
  }
  .title_section_7 {
    font-size: 8pt;
  }
  .descricao_section_7 {
    font-size: 12pt;
  }
  .p_section_7 {
    font-size: 10pt;
  }
  .txt_section_7 {
    font-size: 10pt;
  }
  label {
    font-size: 10pt;
  }
  textarea {
    height: 115px;
    font-size: 9pt;
  }
  input {
    font-size: 9pt;
    height: 45px;
  }
  .btn_form {
    padding: 10px;
  }
  .coluna_form {
    padding: 25px;
    margin-bottom: 40px;
  }
  .copy {
    text-align: center;
  }
  .navbar-toggler {
    color: #ff0000;
  }
  .nav_color {
    padding: 26px;
  }
  .coluna2 {
    padding: 15px;
  }
}
</style>